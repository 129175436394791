import { Divider, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'

interface ReferentielHoraireProps {
  horaire: string | null
}

export const ReferentielHoraire: FunctionComponent<ReferentielHoraireProps> = ({ horaire }) => {
  return (
    <>
      <View style={styles.horaireContainer}>
        <View style={styles.horaireLeftContainer}>
          <Icon as={MaterialIcons} name="event" size={6} color="black" />
        </View>
        <View style={styles.horaireInfoContainer}>
          <Text style={styles.horaireText}>{horaire}</Text>
        </View>
      </View>
      <Divider />
    </>
  )
}

const styles = StyleSheet.create({
  horaireContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 5,
    marginBottom: 10,
    marginTop: 10,
  },
  horaireLeftContainer: {
    marginRight: 10,
  },
  horaireInfoContainer: {
    flex: 1,
  },
  horaireText: {
    fontSize: 14,
  },
  calendarButton: {
    width: 160,
    borderRadius: 20,
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  calendarButtonText: {
    fontSize: 11,
  },
  iconCalendar: {
    marginRight: 5,
  },
})
