import { StackScreenProps } from '@react-navigation/stack'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { LeftButtonBack, Page } from '../../components/Page'
import { WeeklyScheduleItem } from '../../components/WeeklyScheduleItem'
import { ActionViewParamsType } from '../../navigation/Routes'
import { WeeklySchedulesActions } from '../../redux/WeeklySchedules/actions'
import { weeklySchedulesSelector } from '../../redux/WeeklySchedules/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'

export const WeeklySchedules: FunctionComponent<StackScreenProps<ActionViewParamsType, 'WeeklySchedules'>> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.id as number
  const referentiels = route.params.ids as number[]

  const weeklySchedules = useSelector((state: RootState) => weeklySchedulesSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_WEEKLY_SCHEDULES))

  const dispatch = useDispatch()
  const loadWeekly = () => {
    dispatch(WeeklySchedulesActions.loadWeeklySchedules(id, referentiels))
  }
  useEffect(loadWeekly, [])

  const renderContent = () => {
    if (!weeklySchedules || isLoading) {
      return <Spinner />
    }
    return (
      <Carousel
        mode="parallax"
        data={weeklySchedules}
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
        renderItem={result => <WeeklyScheduleItem item={result.item} />}
        width={theme.deviceWidth}
        loop={false}
        modeConfig={{
          parallaxScrollingScale: 0.95,
          parallaxScrollingOffset: 30,
        }}
      />
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <View style={styles.content}>{renderContent()}</View>
    </Page>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
})
