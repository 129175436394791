export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '8bd20ccf-70bd-41db-800b-f0bb3d4d678e',
  ENV: 'neuillySurSeineAgent',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
    authentication: {
      enabled: true,
      appleProviderEnabled: false,
      facebookProviderEnabled: false,
      googleProviderEnabled: false,
    },
  },
  emailValidationDynamicLink: 'https://nvpagent.page.link/email-confirmation',
  baseSurveyUrl: 'https://sondage.vooter.co/survey',
}
