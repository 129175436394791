import { isEmpty } from 'lodash'
import { Button, Icon, Text, useTheme, View } from 'native-base'
import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useDispatch, useSelector } from 'react-redux'
import { SurveyItem, SurveyStatus } from '../../api/Surveys/types'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { MeActions } from '../../redux/me/actions'
import { RootState } from '../../redux/reducers'
import { SurveysActions } from '../../redux/surveys/action'
import { lastPageSelector, SurveysSelector } from '../../redux/surveys/selector'
import { fromDDMMYYYYtoDDMMYYYY } from '../../services/dateFormatter'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'
import { FlatListPaging } from '../FlatListPaging'

interface SurveyListProps {
  id?: number
}

const SurveyList: FunctionComponent<SurveyListProps> = ({ id = 0 }) => {
  const { variables } = useTheme()

  const dispatch = useDispatch()
  const surveys = useSelector((state: RootState) => SurveysSelector(state, id?.toString()))
  const me = useSelector((state: RootState) => state.me.me)

  useEffect(() => {
    if (me.useruuid === '') {
      dispatch(MeActions.loadMe())
    }
    dispatch(SurveysActions.loadSurveys(true, id))
  }, [])

  const isLoadingSurveys = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_SURVEYS))

  const isLastPage = useSelector((state: RootState) => lastPageSelector(state, id?.toString()))

  const onEndReached = () => {
    canLoadMore() ? dispatch(SurveysActions.loadSurveys(false, id)) : null
  }

  const onRefresh = () => {
    dispatch(SurveysActions.refreshSurveys(id))
  }

  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_SURVEYS))

  const isLoadingPage = () => isEmpty(surveys) && isLoadingSurveys && !isRefreshing
  const isLoadingMore = () => !isLastPage && !isRefreshing
  const canLoadMore = () => !isLoadingSurveys && isLoadingMore()

  const getStatusElement = useCallback(
    (item: SurveyItem) => {
      switch (item.publication.status) {
        case SurveyStatus.PUBLISHED:
          if (item.nbQuestions === item.nbQuestionsAnswered) {
            return <Text style={styles.textAnswered}>{i18n.t('surveys.status.answered')}</Text>
          }
          return (
            <Button
              style={styles.button}
              size="xs"
              colorScheme="primary"
              onPress={() => navigate(Routes.SurveyPageForm, { uuid: item.uuid, title: item.title, useruuid: me.useruuid })}
            >
              {i18n.t('surveys.status.toAnswer').toUpperCase()}
            </Button>
          )
        case SurveyStatus.EXPIRED:
          return <Text style={styles.textExpired}>{i18n.t('surveys.status.expired')}</Text>
        default:
          return null
      }
    },
    [me],
  )

  const renderHeader = () => {
    return (
      <View style={[styles.headerWrapper, { backgroundColor: variables.statusBarColor }]}>
        <Icon as={MaterialIcons} name="comment-question-outline" size={40} color={theme?.colors.white} />
        <Text style={styles.headerTitle}>{i18n.t('surveys.listHeader.title')}</Text>
        <Text style={styles.headerText}>{i18n.t('surveys.listHeader.description')}</Text>
      </View>
    )
  }

  return (
    <View style={[styles.container, { backgroundColor: variables.statusBarColor }]}>
      <SafeAreaView style={styles.container}>
        <FlatListPaging
          data={surveys}
          headerComponent={renderHeader}
          renderItem={({ item }) => (
            <View style={styles.card}>
              <Text style={styles.date}>{fromDDMMYYYYtoDDMMYYYY(item.publication.startDate, item.publication.endDate)}</Text>
              <Text style={styles.title}>{item.title}</Text>
              {getStatusElement(item)}
            </View>
          )}
          isLoadingPage={isLoadingPage()}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.id.toString()}
          flatListStyle={{ padding: theme.padding.x1 }}
        />
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  view: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    paddingHorizontal: theme.padding.x3,
    paddingVertical: theme.padding.x1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  headerTitle: {
    color: theme.colors.white,
    lineHeight: theme.textSize.x4,
    fontSize: theme.textSize.x3,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  headerText: {
    color: theme.colors.white,
    fontSize: theme.textSize.x1_75,
    lineHeight: theme.textSize.x2,
    textAlign: 'center',
    paddingVertical: theme.padding.x2,
  },
  button: {
    flex: 1,
    width: '60%',
    fontSize: theme.textSize.x1,
    marginHorizontal: theme.padding.x1,
    marginVertical: theme.padding.x1,
    alignSelf: 'center',
  },
  card: {
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    padding: 10,
    margin: 5,
    marginHorizontal: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    alignSelf: 'flex-start',
    color: 'grey',
    fontSize: 12,
    marginBottom: 5,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 5,
  },
  textExpired: {
    color: theme.colors.red,
    fontStyle: 'italic',
  },
  textAnswered: {
    color: 'grey',
    fontStyle: 'italic',
  },
})

export { SurveyList }
