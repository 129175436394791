export default {
  home: {
    loginButton: 'Go to Login Page',
    title: 'Home',
  },
  sidebar: {
    version: 'Version',
  },
  login: {
    userName: 'User Name',
    password: 'Password',
    homeButton: 'Go to Home Page',
    title: 'Login page',
    redux: 'Redux test',
    loader: 'Toggle selector',
  },
  zones: {
    consult: 'Consult',
  },
  notifications: {
    notifications: 'Notifications',
    messageEmpty: 'you do not have notifications',
    settingTitle: 'Custom the service',
    categorieAll: 'Receive notifications',
    enabledNotif: 'Enable notifications',
    disabledNotif: 'Disable notifications',
    information: 'Notifications alert you to any emergency or event taking place in your city. You can customize this sevice by selecting the categories below',
    permissionDenied: 'Notification disabled',
    phonePermission: 'In the phone settings, open on the application and activate notifications if you wish to benefit',
    moreInfo: 'En savoir plus',
  },
  date: {
    LeDDMMYYYatHHMM: "MMMM dd yyyy 'at' HH'h'mm",
    LeDDMMYYY: 'MM/dd/yyyy',
    DurationYears: "''y' years ago'",
    DurationMonths: "''M' months ago'",
    DurationDays: "''d' days ago'",
    DurationHHMM: "''h' hours and 'm' minutes ago'",
    DurationHH: "''h' hours ago'",
    DurationMMSS: "''m ' minutes and ' s' seconds ago'",
    DurationMM: "''m ' minutes ago'",
    DurationSS: "''s' seconds ago'",
    now: 'now',
    updated: 'Updated ',
    fromDDMMYYYY: "'from' MM/dd/yyyy",
    toDDMMYYYY: "'to' MM/dd/yyyy",
  },
  toast: {
    error: 'Oups! An error occured!\nPlease retry later :)',
    thankyou: 'Thank you',
    closed: 'Closed',
    success: 'Operation successfully executed',
  },
  gridActu: {
    lastActu: 'Last news',
  },
  cartography: {
    go: 'go!',
  },
  favorites: {
    title: 'Your favorites',
    add: 'Add a service',
    titleSelect: 'Select a Service',
    noServices: 'No services found',
    multiChoice: {
      add: 'Add to home screen',
    },
  },
  form: {
    noForm: 'You are not connected to the internet. Check your connection and start again',
    zipCode: 'Zip code',
    city: 'City',
    success: 'Your submition has been taken into account !',
    selectItem: 'Select an item',
  },
  validation: {
    error: 'There are errors in your form.',
    required: 'This field is mandatory',
    email: `Mail address is invalid`,
    max: 'Max size exceeded ({{count}})',
    rgpd: 'GDPR need to be accepted to submit form',
  },
  referentiel: {
    search: 'Filter',
    apply: 'Filter',
    closed: 'closed',
    thisWeek: 'Attendance this week',
    today: 'Attendance today',
  },
  signalement: {
    next: 'Next',
    title: 'Reports',
    send: 'Send',
    state: 'State',
    comment: 'Your comment',
    comments: 'Comments',
    yourReport: 'Your report',
    process: 'Process',
    pageTitle: 'Send a message',
    citizenAuthor: 'You wrote:',
    cityAuthor: 'City',
    contactBtn: {
      label: 'Send a message',
    },
    contact: {
      title: 'Enter your message to the city',
      titleInfo: 'The person responsible for processing your request will be informed.',
      sendBtn: 'Send my message',
      cancel: 'Cancel',
      message_required: 'This field is required',
      message_min: 'The minimum number of characters is 2',
      message_max: 'The maximum number of characters is 1000',
      success_message: 'Your message has been sent',
      error_message: 'Your message could not be sent. Please try again',
    },
  },
  imageUpload: {
    title: 'Select a picture',
    cancel: 'Cancel',
    takePhoto: 'Take Photo...',
    chooseFromLibrary: 'Choose from library...',
    error: "This photo isn't valide",
  },
  add: 'Add',
  noData: 'No data to display',
  more: 'More',
  call: 'Call',
  phone: 'Tel: {{phone}}',
  gpsCard: {
    gps: 'GPS:',
    errorMessage: "You didn't authorize us to use your position.",
    errorLocation: "Oops... we didn't manage to recover your position.",
    yourPosition: 'Your position:',
  },
  website: 'Website',
  days: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  daysShort: {
    sunday: 'Su',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
  },
  legend: 'legend',
  back: 'Back',
  view: 'View',
  tideTime: {
    now: 'Now:',
    theoretical: 'These data are theoretical, and do not take into account weather conditions.',
  },
  offline: {
    title: 'Oups',
    line1: 'You seem offline',
    line2: 'Please, make sure you are connected to a network.',
    retry: 'Retry',
  },
  state: 'Etat',
  agenda: {
    at: 'at',
  },
  movie: {
    now: `Now`,
    coming: `Coming`,
    todayAt: `Today at`,
    next: 'Next',
    duration: 'Duration',
    from: 'From',
    with: 'With',
    genre: 'Genre',
    subtitles: 'Subtitles',
  },
  authorization: {
    letPhoneAccess: `In the phone settings, press on the app and activate the authorization`,
  },
  promotion: {
    continue: 'Continue',
    start: 'Start',
  },
  grcYpok: {
    login: 'Login',
    open: 'Open',
    accessAnonymous: 'Access without authentication',
    serviceBeingProcessed: 'service being processed',
    tryLater: 'Access to the service unavailable. Please try again later',
    yourLoggedAs: 'You are logged in as',
    forgot: 'Forgot your password ?',
  },
  permission: {
    ok: 'Ok',
    cancel: 'Cancel',
    titleFineLocation: 'Access your location',
    messageFineLocation:
      'This allows us to use your location to provide you with certain functions like your position on a city map, to make a geolocated request (road works, waste, etc...).',
    messageCameraAccess: 'This allows us to take a picture for certain functions, such as making a request or reporting a report',
    askMeLater: 'Ask Me Later',
  },
  notAvailable: 'not available',
  addCity: {
    title: 'Add a city',
    searchTitle: 'Search a city',
    searchPlaceholder: 'City, postal code ...',
    successAdd: 'The city has been added to your favorites',
    citySelected: 'You already have the city %{name} in favorites',
  },
  myCitys: {
    title: 'My citys',
    emptySelectCity: "You don't have a city yet",
    removeTitle: 'Remove city',
    removeMessage: 'Do you want to remove %{name} from your favorites ?',
  },
  confirm: {
    yes: 'Yes',
    no: 'No',
  },
  airQuality: {
    title: 'Air quality',
    good: 'Good',
    way: 'Way',
    poor: 'Poor',
    bad: 'Bad',
  },
  action: {
    add: 'Add',
    cancel: 'Cancel',
    update: 'Update',
  },
  serviceUnavailable: 'Service unavailable, we invite you to try again later',
  weather: {
    title: 'Weather',
  },
  updatedTime: 'updated: ',
  signIn: {
    welcome: 'Welcome !',
    subtitle: 'Sign in or sign up to continue',
    email: {
      label: 'E-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
    forgetPassword: 'Forgot your password?',
    connectWithEmail: 'Connect with your e-mail',
    continueAsGuest: 'Continue as a guest',
    or: 'Or',
    connectWithGoogle: 'Continue with Google',
    connectWithFacebook: 'Continue with Facebook',
    connectWithApple: 'Continue with Apple',
    noAccountYet: 'No account yet?',
    createOne: 'Create one!',
    wrongCredentials: 'Unable to connect.\nEmail and password does not match.',
    accountExistWithExistingCredential: 'Account already exists. Please try again with another connexion method',
  },
  signOut: {
    disconnecing: 'Disconnecting',
  },
  signUp: {
    welcome: 'Create your account!',
    subtitle: 'Sign in or sign up to continue',
    email: {
      label: 'E-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
    connectWithEmail: 'Create my account',
    alreadyHaveAccount: 'Already have an account?',
    connect: 'Sign in',
    acceptCgu: {
      part1: 'I accept',
      part2: 'terms and conditions',
      part3: 'of the application',
    },
    error: {
      emailAlreadyUsed: 'Error: Email already used',
    },
  },
  myAccount: {
    title: 'My account',
    parameters: {
      title: 'My account parameters',
      logout: 'Logout',
      personalInfo: 'Personal information',
      deleteAccount: 'Delete Account',
      changePassword: 'Change password',
      deleteAccountModal: {
        title: 'Delete account',
        description: 'Do you really want to delete your account?',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
    },
    myAccountDeletion: {
      subtitle: 'My account',
      title: 'Delete my account',
      parameters: {
        title: 'Important',
        information: 'This section allows you to delete your account.',
        warning: 'If you go through the process, you will lose all your data. Your reports will be anonymised.',
      },
    },
    myAccountConfirmDeletion: {
      parameters: {
        title: 'You are about to delete your account',
        question: 'Are you sure you want to continue ?',
        firstInfo: 'You will no longer have access to your alerts',
        secondInfo: 'Your reports and associated data will be anonymised',
      },
      confirm: 'Enter "DELETE" in the field below',
      keyword: 'DELETE',
      alert: 'Deletion has failed',
    },
    myAccountWasDeleted: {
      parameters: {
        title: 'Your account has been deleted',
        informations: 'You can always browse the application as a guest or recreate an account.',
        message: 'We hope to see you soon.',
      },
      confirm: 'Back to home page',
    },
    preferences: {
      title: 'Preferences',
      notifications: 'Notifications',
    },
  },
  resetPassword: {
    title: 'Reset your password',
    subtitle: 'Enter your e-mail. We will send you a link to reset it.',
    email: {
      label: 'E-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    sendResetLink: 'Receive reset link',
  },
  personalInfo: {
    title: 'Personal information',
    firstName: {
      label: 'First Name',
      placeholder: 'Ex: Mathieu',
    },
    lastName: {
      label: 'Last Name',
      placeholder: 'Ex: Lessitoyen',
    },
  },
  changePassword: {
    title: 'Changer my password',
    oldPassword: {
      label: 'Old password',
    },
    newPassword: {
      label: 'New password',
    },
    confirmNewPassword: {
      label: 'Confirm new password',
    },
    confirmChangePassword: 'Update my password',
    successMessage: 'Password has been successfully changed',
    warning: {
      passwordNotMatching: {
        title: 'Warning',
        content: 'Password and confirmation not matching.\nPlease correct your entry.',
      },
      passwordNotValid: {
        title: 'Important',
        content: 'Your password needs to meet following rules:\n- At least 8 characters\n- Include one letter\n- Include one number',
      },
    },
  },
  emailVerificationSent: {
    title: 'Almost there',
    subtitle: 'You received an email to activate your account',
    description: 'Email has been sent to following address:',
    back: 'Back',
  },
  emailVerificationDone: {
    title: 'Congratulation !',
    subtitle: 'Your account is activated, you can now enjoy services from the application.',
    continue: "OK, let's go!",
  },
  taskReport: {
    defaultPageTitle: 'Task to be processed',
    errorTitle: 'My tasks',
    noGRCAccountTitle: 'No GRC 360 account associated',
    noGRCAccountExplain: 'In order to process your tasks on the mobile application, you must have a GRC 360 account attached to your email address',
    noGRCAccountFooter: 'Please contact your manager for more information.',
    commonErrorTitle: 'Unable to display the requested page',
    commonErrorExplain: 'Check that you are connected to the network and refresh the page.',
    commonErrorFooter: 'If the problem persists, contact your administrator.',
    refreshPage: 'Refresh page',
  },
  CGU: {
    noCGU: 'no CGU available',
  },
  tide: {
    title: 'Tide times',
    unit: 'm',
    coeffAbbreviation: 'Coeff.',
    today: `Today`,
  },
  surveys: {
    fallbackTitle: 'Survey',
    status: {
      toAnswer: 'Answer now',
      answered: 'You already answered this survey',
      expired: 'This survey has ended',
    },
    listHeader: {
      title: 'Citizen participation',
      description: 'You are here in the consultations. The City asks your opinion on different subjects.',
    },
  },
}
