import { Divider, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Entypo from 'react-native-vector-icons/Entypo'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { Latlng } from '../../api/types'
import { i18n } from '../../lib/i18n'
import { themeSelector } from '../../redux/theme/selector'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'

interface CardAddressProps {
  address: string | null
  positionMap: Latlng | null
}

export const CardAddress: FunctionComponent<CardAddressProps> = ({ address, positionMap }) => {
  const theme = useSelector(themeSelector)
  if (!address) {
    return null
  }

  const goToMap = positionMap
    ? getItemAction({
        type: 'map',
        label: address,
        ...positionMap,
      })
    : undefined

  return (
    <>
      <View style={styles.addressContainer}>
        <View style={styles.addressLeftContainer}>
          <Icon as={Entypo} name="location-pin" size={6} color="black" />
        </View>
        <View style={styles.addressInfoContainer}>
          <Text style={styles.addressText}>{address}</Text>
        </View>
        {positionMap && (
          <TouchableOpacity style={[styles.navigationButton, { borderColor: theme?.toolbarDefaultBg }]} onPress={goToMap}>
            <View style={styles.buttonContent}>
              <Icon as={Ionicons} name="navigate" size={5} style={styles.iconNavigate} color={theme?.toolbarDefaultBg} />
              <Text style={[styles.navigationButtonText, { color: theme?.toolbarDefaultBg }]}>{i18n.t('cardAddress.itinerary')}</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
      <Divider />
    </>
  )
}

const styles = StyleSheet.create({
  addressContainer: {
    flexDirection: 'row',
    paddingVertical: theme.padding.x2,
    paddingHorizontal: theme.padding.x1,
    marginBottom: theme.padding.x2,
  },
  addressLeftContainer: {
    marginRight: theme.padding.x1,
  },
  addressInfoContainer: {
    flex: 1,
  },
  addressText: {
    fontSize: theme.textSize.x1_75,
  },
  navigationButton: {
    width: 160,
    borderRadius: theme.padding.x3,
    borderWidth: 1,
    paddingVertical: theme.padding.x1,
    paddingHorizontal: theme.padding.x2,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },
  navigationButtonText: {
    fontSize: theme.textSize.x1_50,
  },
  iconNavigate: {
    transform: [{ rotate: '-45deg' }],
  },
})
