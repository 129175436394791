import React from 'react'
import * as Yup from 'yup'
import { DecoTypes, FieldValueRequest, FormAddress, FormDefaultValue, FormElement, FormFieldElement, FormSingleCheckbox, FormText } from '../../api/Form/types'
import {
  AddressField,
  CheckboxField,
  DateField,
  ImageField,
  LabelField,
  LineBreakField,
  SectorDayField,
  SectorField,
  SelectField,
  SingleCheckboxField,
  TextField,
} from '../../components/Fields'
import { CategoryField } from '../../components/Fields/CategoryField.component'
import { AddressValue, DefaultFieldValue, FormElementProps } from '../../components/Fields/types'
import { i18n } from '../../lib/i18n'

type YupSchemaElement = Yup.StringSchema<string | null> | Yup.NumberSchema<number | null> | Yup.BooleanSchema<boolean | null> | Yup.ObjectSchema<AddressValue | null>

export const getField = (elements: FormElement[], props: FormElementProps<FormElement>) => {
  const { field, value, onDataLoad, onDataEndLoad, ...inputProps } = props
  const fieldId = `${field.id}`
  switch (field.type) {
    case 'field_email':
    case 'field_texte':
    case 'field_phone':
      return <TextField {...inputProps} name={fieldId} field={field} value={value} />
    case 'field_select':
      return <SelectField {...inputProps} name={fieldId} field={field} value={value} />
    case 'field_radio':
    case 'field_checkboxes':
      return <CheckboxField {...inputProps} name={fieldId} field={field} value={value as number[]} />
    case 'field_checkbox':
      return <SingleCheckboxField {...inputProps} name={fieldId} field={field} value={value as boolean} />
    case 'field_date':
      return <DateField {...inputProps} name={fieldId} field={field} value={value as number} />
    case 'deco_label':
    case 'deco_image':
    case 'deco_html':
      return <LabelField {...inputProps} name={fieldId} field={field} value={value} />
    case 'deco_linebreak':
    case 'deco_space':
      return <LineBreakField {...inputProps} name={fieldId} field={field} value={value} />
    case 'field_adresse':
      return <AddressField {...inputProps} name={fieldId} field={field} value={value as AddressValue} />
    case 'field_secteur':
      return <SectorField {...inputProps} name={fieldId} field={field} value={value as number} />
    case 'field_secteurjour': {
      const sectorField = elements.find(element => element.type === 'field_secteur')
      const sectorFieldId = (sectorField && sectorField.id) || null

      let sectorId: number | null | undefined
      if (sectorFieldId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sectorId = (props.values as any)[sectorFieldId] as number | null
      }

      return <SectorDayField {...inputProps} sectorId={sectorId} name={fieldId} field={field} value={value as number} />
    }
    case 'field_images':
      return <ImageField {...inputProps} onDataLoad={onDataLoad} onDataEndLoad={onDataEndLoad} name={fieldId} field={field} value={value as string[]} />
    case 'field_souscategorie':
      return <CategoryField {...inputProps} onDataLoad={onDataLoad} onDataEndLoad={onDataEndLoad} name={fieldId} field={field} value={value as number} />
    default:
      return null
  }
}

const addMandatoryRule = (field: FormFieldElement, fieldSchema: YupSchemaElement) => {
  switch (field.type) {
    case 'field_checkbox':
      return (fieldSchema as Yup.BooleanSchema<boolean | null>).oneOf([true], i18n.t('validation.required')).required(i18n.t('validation.required'))
    case 'field_adresse':
      if (!field.constraint.mandatory) {
        return fieldSchema
      }
      return (fieldSchema as Yup.ObjectSchema<AddressValue | null>).required(i18n.t('validation.required'))
    default:
      return fieldSchema.required(i18n.t('validation.required'))
  }
}

const addConstraintMaxSize = (field: FormAddress | FormText, fieldSchema: Yup.AnyObject) => {
  if (field.constraint.maxSize) {
    return fieldSchema.max(field.constraint.maxSize, i18n.t('validation.max', { count: field.constraint.maxSize }))
  }
  return fieldSchema
}

const generateAddressSchema = (field: FormAddress) => {
  const rule = addMandatoryRule(field, addConstraintMaxSize(field, Yup.string().nullable())) as Yup.StringSchema<string | null>
  const otherFields = field.constraint.detailAddress ? rule : addConstraintMaxSize(field, Yup.string().nullable())
  return Yup.object({
    adresse: rule,
    codePostal: otherFields,
    ville: otherFields,
  }).nullable()
}

export const generateSchema = (fields: FormElement[]) => {
  const yupSchema = fields.reduce((schema: Yup.AnyObject, field: FormElement) => {
    if (DecoTypes.includes(field.type)) {
      return schema
    }

    const fieldElement = field as FormFieldElement
    const id = (fieldElement.id || '').toString()
    const { constraint } = fieldElement
    /**
     * Define validation type (string, email, number, nullable)
     */

    switch (fieldElement.type) {
      case 'field_checkbox':
        schema[id] = Yup.boolean().nullable()
        break
      case 'field_radio':
      case 'field_date':
        schema[id] = Yup.number().nullable()
        break
      case 'field_email': {
        const emailSchema = Yup.string().trim().nullable().email(i18n.t('validation.email'))
        schema[id] = addConstraintMaxSize(fieldElement, emailSchema)
        break
      }
      case 'field_texte':
      case 'field_phone':
        schema[id] = addConstraintMaxSize(fieldElement, Yup.string().nullable())
        break
      case 'field_adresse':
        schema[id] = generateAddressSchema(fieldElement)
        break
      case 'field_images':
        schema[id] = Yup.array()
          .of(Yup.string().nullable())
          .min(!fieldElement.constraint.mandatory ? 0 : 1, i18n.t('validation.required'))
        break
      case 'field_souscategorie':
        schema[id] = Yup.string().nullable()
        break
      case 'field_checkboxes':
        schema[id] = Yup.array()
          .of(Yup.string().nullable())
          .min(!fieldElement.constraint.mandatory ? 0 : 1, i18n.t('validation.required'))
        break
      default:
        schema[id] = Yup.string().nullable()
        break
    }

    /**
     * Define required constraint validation.
     */
    if (constraint && constraint.mandatory) {
      schema[id] = addMandatoryRule(fieldElement, schema[id])
    }

    return schema
  }, {})

  return Yup.object().shape(yupSchema)
}

export const GDPR_ID = -1

export const addGDPRCheckbox = (rgpdMessage: string, fields: FormElement[]): FormElement[] => {
  if (rgpdMessage) {
    const gdprField: FormSingleCheckbox = {
      type: 'field_checkbox',
      id: GDPR_ID,
      libelle: rgpdMessage,
      code: null,
      constraint: {
        mandatory: true,
        checkDefaut: false,
      },
      ordre: fields.length,
    }
    return [...fields, gdprField]
  }
  return fields
}

export const initDefaultValues = (fields: FormElement[]) =>
  fields.reduce((values: DefaultFieldValue, element) => {
    values[(element.id || '').toString()] = null
    return values
  }, {})

export const getFormDefaultValues = (defaultValues: FormDefaultValue[]) =>
  defaultValues.reduce((values: DefaultFieldValue, defaultValue) => {
    values[defaultValue.id.toString()] = defaultValue.value
    return values
  }, {})

/**
 * Filter values and send to the API
 * the fields without decoration & GDPR field
 * @param values all field values
 */
export const sanitizeFormikValues = (values: DefaultFieldValue, mapFieldIdTypes: Map<number, string | null>, filterGDPR = true): FieldValueRequest[] => {
  const keys = Object.keys(values)

  const fieldValues: FieldValueRequest[] = keys.map(key => {
    // eslint-disable-next-line radix
    const id = parseInt(key)
    return {
      id,
      type: mapFieldIdTypes.get(id) || '',
      value: values[key],
    }
  })

  const filteredValues = fieldValues.filter(field => !DecoTypes.includes(field.type))
  if (filterGDPR) {
    return filteredValues.filter(field => field.id !== GDPR_ID)
  }
  return filteredValues
}
