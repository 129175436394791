import dynamicLinks, { FirebaseDynamicLinksTypes } from '@react-native-firebase/dynamic-links'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { Button, Icon, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { Linking, Platform } from 'react-native'
import RNBootSplash from 'react-native-bootsplash'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { HomeComponentName } from '../../api/Home'
import { GridPageList } from '../../components/GridPageList'
import { MyCitysHome } from '../../components/MyCitysHome/MyCitysHome.component'
import { NotificationButton } from '../../components/NotificationButton'
import { Page } from '../../components/Page'
import { env } from '../../environment'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, RoutesPromotion } from '../../navigation/Routes'
import { apiTokenSelector } from '../../redux/authentication/selectors'
import { homeComponentNameSelector, homeTitleImageSelector, homeTitleSelector, promotionSelector } from '../../redux/home/selector'
import { hasNewNotificationSelector, notificationsEnabledSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { deepLinkNavigate } from '../../services/navigation'
import { checkNotificationPermission, removeNotificationListeners, requestAndroidNotificationPermission } from '../../services/notification'
import { UserAuthenticationButton } from './components/UserAuthenticationButton/UserAuthenticationButton.component'

const getComponent = (componentName: HomeComponentName) => {
  switch (componentName) {
    case 'favorites':
      return <View />
    case 'news':
      RNBootSplash.hide()
      return <MyCitysHome />
    default:
      return <GridPageList isFavoriesEnabled={env().featureFlags.isFavoritesEnabled} />
  }
}

export const Home: FunctionComponent<DrawerScreenProps<ActionViewParamsType, 'Home'>> = props => {
  const title = useSelector(homeTitleSelector)
  const titleHome = title || i18n.t('home.title')
  const titleImage = useSelector(homeTitleImageSelector)
  const promotion = useSelector(promotionSelector)
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const theme = useSelector(themeSelector)

  const permission = useSelector((state: RootState) => notificationsEnabledSelector(state))
  const hasNewNotification = useSelector((state: RootState) => hasNewNotificationSelector(state))
  const HomeComponentName = useSelector(homeComponentNameSelector)

  const initHome = async () => {
    try {
      Linking.getInitialURL().then(url => {
        if (url) {
          deepLinkNavigate({ url })
        }
      })

      if (Platform.OS === 'android') {
        await requestAndroidNotificationPermission()
      }
      await checkNotificationPermission()
    } catch (error) {
      console.error(error)
    }
  }

  const goToPromotion = () => {
    if (promotion) {
      setTimeout(
        () => {
          props.navigation.navigate({ name: RoutesPromotion.Promotion, params: {} })
        },
        Platform.OS === 'web' ? 250 : 0,
      )
    }
  }

  const handleDynamicLink = (link: FirebaseDynamicLinksTypes.DynamicLink) => {
    deepLinkNavigate(link as { url: string })
  }

  useEffect(() => {
    // Handle firebase dynamic links at app startup
    if (Platform.OS !== 'web') {
      dynamicLinks()
        .getInitialLink()
        .then(link => {
          if (link) {
            handleDynamicLink(link)
          }
        })
    }
  }, [])

  useEffect(() => {
    // Handle firebase dynamic links
    if (Platform.OS !== 'web') {
      const unsubscribe = dynamicLinks().onLink(handleDynamicLink)
      return () => unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (authtoken) {
      Linking.addEventListener('url', deepLinkNavigate)
      initHome()
    }
    return () => {
      // Linking.removeEventListener('url', deepLinkNavigate)
      if (authtoken) removeNotificationListeners()
    }
  }, [authtoken])

  useEffect(goToPromotion, [promotion])

  const notificationProps = {
    enabled: Platform.OS === 'web' ? true : permission,
    hasNewNotification,
  }

  return (
    <Page
      title={titleHome}
      titleImage={titleImage}
      left={
        <Button variant="ghost" onPress={props.navigation.toggleDrawer}>
          <Icon as={Ionicons} name="menu" size={7} color={theme?.titleFontColor} />
        </Button>
      }
      right={
        <>
          <NotificationButton {...notificationProps} />
          <UserAuthenticationButton />
        </>
      }
    >
      {getComponent(HomeComponentName)}
    </Page>
  )
}
