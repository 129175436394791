import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { Button, Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { CardAddress } from '../../components/CardAddress'
import { HeaderScrollView } from '../../components/HeaderScrollView'
import { LeftButtonBack, LeftRoundButtonBack } from '../../components/Page/Page.component'
import { Separator } from '../../components/Separator'
import { SignalementCommentList } from '../../components/SignalementItem/components/SignalementCommentList.component'
import { SignalementDescription } from '../../components/SignalementItem/components/SignalementDescription.component'
import { SignalementItemEtat } from '../../components/SignalementItem/components/SignalementItemEtat.component'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, Routes } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { SignalementsActions } from '../../redux/signalements/action'
import { signalementItemSelector } from '../../redux/signalements/selector'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { theme } from '../../theme'

import imageDefault from '../../assets/images/empty_image.jpg'

export const SignalementItem: FunctionComponent<StackScreenProps<ActionViewParamsType, 'SignalementItem'>> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.id as number
  const { image } = route.params

  const dispatch = useDispatch()
  const signalementItem = useSelector((state: RootState) => signalementItemSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_SIGNALEMENT_ITEM))
  const navigation = useNavigation()

  useEffect(() => {
    dispatch(SignalementsActions.loadSignalementItem(id))
  }, [])

  if (!signalementItem || isLoading) {
    const images = image ? [image] : []
    return (
      <HeaderScrollView images={images} imageDefault={imageDefault as ImageSourcePropType} title="" left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
        <Spinner />
      </HeaderScrollView>
    )
  }

  const images = signalementItem && signalementItem.images ? signalementItem.images : []

  return (
    <HeaderScrollView
      images={images}
      imageDefault={imageDefault as ImageSourcePropType}
      title={title || i18n.t('signalement.title')}
      left={<LeftRoundButtonBack />}
      leftHeader={<LeftButtonBack />}
    >
      <View style={styles.content}>
        <Text style={styles.date}>{toDDMMYYYYatHHMM(signalementItem.dateDemande)}</Text>
        <Separator />
        {signalementItem.canAddComments && (
          <Button
            colorScheme="default"
            testID="signalement-contact-button"
            onPress={() => {
              navigation.navigate({ name: Routes.SignalementContact, params: { id } } as never)
            }}
            style={styles.contactBtn}
            leftIcon={<Icon as={Ionicons} name="send" color={theme.colors.white} size={5} mr={1} style={{ transform: [{ rotate: '-45deg' }] }} />}
          >
            <Text style={styles.contactLabel}>{i18n.t('signalement.contactBtn.label')}</Text>
          </Button>
        )}
        <CardAddress address={signalementItem.address} positionMap={signalementItem.positionMap} />
        <SignalementItemEtat etat={signalementItem.etat} />
        <SignalementDescription description={signalementItem.description} />
        <SignalementCommentList comments={signalementItem.commentaires} />
      </View>
    </HeaderScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: theme.padding.x2,
  },
  date: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    color: theme.colors.silver,
  },
  contactBtn: {
    width: '100%',
    marginVertical: theme.padding.x1,
  },
  contactLabel: {
    textAlign: 'center',
    fontSize: 16,
    color: theme.colors.white,
  },
})
