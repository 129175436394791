import { ScrollView, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { ImageSourcePropType, StyleSheet, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { ReferentielsActions } from '../../redux/referentiels/actions'
import { referentielItemSelector } from '../../redux/referentiels/selector'
import { theme } from '../../theme'
import { CardAddress } from '../CardAddress'
import { HtmlWebView } from '../WebView'
import { ReferentielFields, ReferentielHoraire, ReferentielImage, ReferentielInfos, ReferentielLinks, ReferentielMedia } from './components'

import imageDefault from '../../assets/images/empty_image.jpg'

const MAX_HEIGHT = theme.deviceHeight / 2.7

interface ReferentielProps {
  id: number
}

export const Referentiel: FunctionComponent<ReferentielProps> = ({ id }) => {
  const dispatch = useDispatch()

  const referentiel = useSelector((state: RootState) => referentielItemSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_ACTUALITE))

  const loadReferentiel = () => {
    dispatch(ReferentielsActions.loadReferentielItem(id))
  }
  useEffect(loadReferentiel, [])

  if (!referentiel || isLoading) {
    return (
      <ScrollView>
        <ReferentielImage title="..." image={imageDefault as ImageSourcePropType} maxHeight={theme.referentielImageMaxHeight} />
        <Spinner />
      </ScrollView>
    )
  }
  const imgSrc = referentiel && referentiel.image ? referentiel.image : null

  return (
    <ScrollView>
      <ReferentielImage title={referentiel.libelle} image={imageDefault as ImageSourcePropType} fastImage={imgSrc} maxHeight={MAX_HEIGHT} />
      <View style={styles.content}>
        {referentiel.categorie && (
          <Text style={styles.categorie} numberOfLines={2}>
            {referentiel.categorie}
          </Text>
        )}

        <ReferentielMedia phone={referentiel.telephone} email={referentiel.email} website={referentiel.siteWeb} media={referentiel.media} />
        <ReferentielHoraire horaire={referentiel.horaire} />
        <CardAddress address={referentiel.adresseFull} positionMap={referentiel.positionMap} />
        <ReferentielLinks links={referentiel.links} />
        <ReferentielInfos infos={referentiel.infos} />
        <ReferentielFields fields={referentiel.fields} />
        <HtmlWebView content={referentiel.description} />
      </View>
    </ScrollView>
  )
}

interface Style {
  content: ViewStyle
  categorie: ViewStyle
}
const styles = StyleSheet.create<Style>({
  content: {
    paddingHorizontal: theme.padding.x2,
  },
  categorie: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    fontSize: theme.textSize.x2,
    color: theme.colors.silver,
  },
})
