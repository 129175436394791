import isEmpty from 'lodash/isEmpty'
import { Button, Icon } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { FormElement } from '../../api/Form/types'
import { ReferentielsApi } from '../../api/Referentiels'
import { ReferentielListItemResult } from '../../api/Referentiels/types'
import { ReferentielFilters, SegmentItemResult } from '../../api/types'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { ReferentielsActions } from '../../redux/referentiels/actions'
import { lastPageReferentielListSelector, referentielListSelector } from '../../redux/referentiels/selector'
import { themeSelector } from '../../redux/theme/selector'
import { sanitizeFormikValues } from '../../services/form'
import { AgendaListItem } from '../AgendaListItem'
import { DefaultFieldValue } from '../Fields/types'
import { FlatListPaging } from '../FlatListPaging'
import { PageSegment } from '../PageSegment'
import { ReferentielSearch } from '../ReferentielSearch'

export type AgendaComponentItem = 'default' | 'withCustomer'

interface AgendaListProps {
  id: number
  title: string
  titleImage?: string | null
  left?: React.ReactNode
  referentielFilters: ReferentielFilters
  componentItem?: AgendaComponentItem
}

export const AgendaList: FunctionComponent<AgendaListProps> = ({ id, title, titleImage, left, referentielFilters, componentItem = 'default' }) => {
  const [currentSegment, setCurrentSegment] = useState<SegmentItemResult | null>(null)

  const referentielResult = useSelector((state: RootState) => referentielListSelector(state, id))
  const isLoadingReferentiels = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_REFERENTIEL_LIST))
  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_REFERENTIEL_LIST))
  const isLastPage = useSelector((state: RootState) => lastPageReferentielListSelector(state, id))

  const [searchFields, setSearchFields] = useState<FormElement[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [displaySearch, setDisplaySearch] = useState(false)
  const [mapFieldIdTypes, setMapFieldIdTypes] = useState<Map<number, string | null>>(new Map())
  const [defaultValues, setDefaultValues] = useState<DefaultFieldValue | null>(null)
  const theme = useSelector(themeSelector)

  if (currentSegment) {
    referentielFilters.schedulePeriodEnum = currentSegment.id
  } else if (referentielFilters.segments && !isEmpty(referentielFilters.segments)) {
    setCurrentSegment(referentielFilters.segments[0])
  }

  const dispatch = useDispatch()

  const initSearchFields = async () => {
    if (referentielFilters.referentielType) {
      const searchFieldResult = await ReferentielsApi.loadSearchFields(referentielFilters.referentielType, referentielFilters.categories)
      setSearchFields(searchFieldResult && searchFieldResult.elements)
    }
  }

  const initializeMapFieldTypeById = () => {
    if (isEmpty(searchFields)) {
      return
    }
    const map = new Map<number, string | null>()
    searchFields.forEach(field => map.set(field.id, field.type))
    setMapFieldIdTypes(map)
  }

  const resetDisplaySearch = () => {
    if (isSearching) setIsSearching(false)
    if (displaySearch) setDisplaySearch(false)
  }

  useEffect(() => {
    dispatch(ReferentielsActions.loadReferentielList(true, id, referentielFilters))
    initSearchFields()
  }, [])
  useEffect(initializeMapFieldTypeById, [searchFields])
  useEffect(resetDisplaySearch, [referentielResult])

  const isLoadingPage = () => isEmpty(referentielResult) && isLoadingReferentiels && !isRefreshing
  const isLoadingMore = () => !isLastPage && !isRefreshing
  const canLoadMore = () => !isLoadingReferentiels && isLoadingMore()

  const onRefresh = () => {
    dispatch(ReferentielsActions.refreshReferentielList(id, referentielFilters))
  }

  const onEndReached = () => {
    canLoadMore() ? dispatch(ReferentielsActions.loadReferentielList(false, id, referentielFilters)) : null
  }

  const fetchReferentielFromSegment = (segment: SegmentItemResult) => {
    if (referentielFilters.schedulePeriodEnum === segment.id) {
      return
    }
    referentielFilters.schedulePeriodEnum = segment.id
    setCurrentSegment(segment)
    dispatch(ReferentielsActions.refreshReferentielList(id, referentielFilters))
  }

  const querySearch = async (values: DefaultFieldValue) => {
    setIsSearching(true)
    setTimeout(() => {
      const fieldValues = sanitizeFormikValues(values, mapFieldIdTypes, false)
      dispatch(ReferentielsActions.loadReferentielList(true, id, referentielFilters, fieldValues))
      setDefaultValues(values)
    }, 300)
    return new Map()
  }

  const renderItem = (item: ReferentielListItemResult) => {
    const withInfo = referentielFilters.agendaRowEnum !== 2
    switch (componentItem) {
      case 'withCustomer':
        return <AgendaListItem item={item} author withInformations={withInfo} />
      default:
        return <AgendaListItem item={item} withInformations={withInfo} />
    }
  }

  return (
    <PageSegment
      title={title || ''}
      titleImage={titleImage}
      currentSegment={currentSegment}
      onSegmentPress={fetchReferentielFromSegment}
      segments={referentielFilters.segments || null}
      left={left}
      right={
        !isEmpty(searchFields) && (
          <Button variant="ghost" onPress={() => setDisplaySearch(true)}>
            <Icon as={Ionicons} name="options" style={styles.optionIcon} color={theme?.titleFontColor} size={6} />
          </Button>
        )
      }
    >
      <>
        <SafeAreaView style={styles.safeArea}>
          <FlatListPaging
            data={referentielResult}
            renderItem={({ item }) => renderItem(item)}
            onEndReached={onEndReached}
            onRefresh={onRefresh}
            isRefreshing={isRefreshing}
            isLoadingPage={isLoadingPage()}
            isLoadingMore={isLoadingMore}
            keyExtractor={item => item.id.toString()}
          />
        </SafeAreaView>
        {displaySearch && (
          <ReferentielSearch
            isVisible={displaySearch}
            onClose={() => setDisplaySearch(false)}
            fields={searchFields}
            defaultValues={defaultValues}
            isSearching={isSearching}
            submit={querySearch}
          />
        )}
      </>
    </PageSegment>
  )
}

const styles = StyleSheet.create({
  optionIcon: {
    transform: [{ rotate: '90deg' }],
  },
  safeArea: {
    flex: 1,
  },
})
