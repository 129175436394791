/* eslint-disable prettier/prettier */
import { Formik, FormikErrors, FormikProps } from 'formik'
import { Button, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useRef, useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native'
import { withNextInputAutoFocusForm } from 'react-native-formik'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useSelector } from 'react-redux'
import { FormElement } from '../../api/Form/types'
import { i18n } from '../../lib/i18n'
import { themeSelector } from '../../redux/theme/selector'
import { GDPR_ID, generateSchema, getField } from '../../services/form'
import { isNullOrUndefined } from '../../services/textUtil'
import { theme } from '../../theme'
import { ErrorsFields } from '../ErrorsFields'
import { DefaultFieldValue } from '../Fields/types'

const Form = withNextInputAutoFocusForm(View)

interface FormBaseProps {
  fields: FormElement[]
  extraFields?: FormElement[]
  defaultValues: DefaultFieldValue
  description?: string
  buttonText: string
  onBackPress?: () => void
  submit: (values: DefaultFieldValue) => Promise<Map<number, string>>
  forceLoading?: boolean
}

interface FieldKeyValue {
  [key: string]: string
}

interface RenderElementParam {
  element: FormElement
  index: number
  numberOfElements: number
  onDataLoad: () => void
  onDataEndLoad: () => void
  props: Omit<FormikProps<DefaultFieldValue>, 'errors'>
}

interface FormikSubmit {
  setSubmitting: (isSubmitting: boolean) => void
  setFieldError(field: string, message: string): void
}

const renderElement = (
  elements: FormElement[],
  { element, index, numberOfElements, onDataLoad, onDataEndLoad, props }: RenderElementParam,
) => {
  const elementId = (element.id || '').toString()
  const returnKeyType = index === numberOfElements - 1 ? 'done' : 'next'

  return (
    <View key={elementId} style={styles.fieldContainer}>
      {getField(elements, {
        returnKeyType,
        field: element,
        value: props.values[elementId],
        name: elementId,
        onDataLoad,
        onDataEndLoad,
        ...props,
      })}
    </View>
  )
}

const displayError = (error?: string | FieldKeyValue) => {
  if (!error) {
    return ''
  }
  if (typeof error === 'string') {
    return error
  }

  return [...new Set(Object.values(error))].join(', ')
}

const generateErrorLabels = (fields: FormElement[], errors: FormikErrors<DefaultFieldValue>) => {
  const fieldLabels = fields.reduce((labels: FieldKeyValue, field: FormElement) => {
    if (field.libelle) {
      const fieldId = `${field.id}`
      labels[fieldId] = field.libelle
    }
    return labels
  }, {})

  return Object.keys(errors).map(errorKey => {
    if (errorKey === GDPR_ID.toString()) {
      return i18n.t('validation.rgpd')
    }

    return `${fieldLabels[errorKey]} : ${displayError(errors[errorKey])}`
  })
}

const FormBase: FunctionComponent<FormBaseProps> = ({
  fields,
  extraFields,
  defaultValues,
  description,
  onBackPress,
  buttonText,
  submit,
  forceLoading,
}) => {
  const [hasFormResponseError, setHasFormResponseError] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const scrollViewRef = useRef<ScrollView>(null)
  const themeSelect = useSelector(themeSelector)

  const onDataLoad = () => setLoadingData(true)
  const onDataEndLoad = () => setLoadingData(false)

  const numberOfElements = fields.length

  const resetFormErrorResponse = () => {
    setHasFormResponseError(false)
  }

  const scrollToTop = () => {
    if (scrollViewRef.current && scrollViewRef.current.scrollTo) {
      scrollViewRef.current.scrollTo({
        x: 0,
        y: 0,
        animated: true,
      })
    }
  }

  const yupSchema = generateSchema(fields)

  const renderErrors = (formErrors: FormikErrors<DefaultFieldValue>, isValidating: boolean) => {
    const hasFormError = Object.keys(formErrors).length > 0
    if (!hasFormError) {
      return null
    }

    if (isValidating || hasFormResponseError) {
      scrollToTop()
      resetFormErrorResponse()
    }

    const errorList = generateErrorLabels([...fields, ...(extraFields || [])], formErrors)
    return <ErrorsFields message={i18n.t('validation.error')} errors={errorList} />
  }

  const submitForm = async (
    values: DefaultFieldValue,
    { setSubmitting, setFieldError }: FormikSubmit,
  ) => {
    const errors = await submit(values)
    if (errors.size) {
      errors.forEach((error: string, fieldId: number) => setFieldError(fieldId.toString(), error))
      setHasFormResponseError(true)
    }
    setSubmitting(false)
  }


  return (
    <SafeAreaView style={styles.safeArea}>
      <KeyboardAwareScrollView
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={scrollViewRef as any}
        enableOnAndroid
        enableAutomaticScroll
        contentContainerStyle={styles.contentContainer}
        style={styles.contentScollView}
      >
        <View style={[styles.masterContainer]}>
          <Formik
            initialValues={defaultValues}
            validationSchema={yupSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={submitForm}
          >
            {({ errors, ...props }) => {
              const isProcessing = props.isSubmitting || isLoadingData || forceLoading
              return (
                <Form>
                  {renderErrors(errors, props.isValidating)}
                  {!isNullOrUndefined(description) && <Text>{description}</Text>}
                  <View style={styles.container}>
                    {fields.map((element, index) =>
                      renderElement(fields, {
                        element,
                        index,
                        numberOfElements,
                        props,
                        onDataLoad,
                        onDataEndLoad,
                      }),
                    )}
                  </View>
                  <View style={styles.buttonContainer}>
                    {onBackPress && (
                      <Button
                        variant="outline"
                        style={styles.button}
                        onPress={!isProcessing ? onBackPress : undefined}
                      >
                        {isProcessing ? (
                          <Spinner style={styles.container} />
                        ) : (
                          <Text style={[styles.buttonText, { color: themeSelect?.brandPrimary }]}>{i18n.t('back')}</Text>
                        )}
                      </Button>
                    )}
                    <Button
                      colorScheme="default"
                      testID="submit-button"
                      style={styles.button}
                      onPress={!isProcessing ? props.submitForm : undefined}
                    >
                      {isProcessing ? (
                        <Spinner style={styles.container} />
                      ) : (
                        <Text style={[styles.buttonText, { color: theme.colors.white }]}>{buttonText}</Text>
                      )}
                    </Button>
                  </View>
                </Form>
              )
            }}
          </Formik>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex :1,
  },
  contentScollView: {
    flex: 1,
    paddingTop: theme.padding.x1,

  },
  contentContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: theme.padding.x1,
    marginBottom: theme.padding.x4,
  },
  button: {
    flex: 1,
    marginHorizontal: theme.padding.x1,
    alignSelf: 'flex-end',
  },
  buttonText: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  masterContainer: {
    paddingHorizontal: theme.padding.x1,
    paddingVertical: 0,
    flex: 1,
    marginBottom: theme.padding.x1,
  },
  fieldContainer: {
    paddingHorizontal: theme.padding.x1,
    paddingVertical: 0,
    marginBottom: theme.padding.x1,
  },
})

export { FormBase }
