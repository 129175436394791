import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useFormik } from 'formik'
import { Button, Icon, Spinner, Text, TextArea, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { SignalementApi } from '../../api/Signalement'
import { LeftButtonBack, Page } from '../../components/Page/Page.component'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { SignalementsActions } from '../../redux/signalements/action'
import { signalementItemSelector } from '../../redux/signalements/selector'
import { showSuccess, showWarning } from '../../services/toast'
import { theme } from '../../theme'

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .required(i18n.t('signalement.contact.message_required'))
    .min(2, i18n.t('signalement.contact.message_min'))
    .max(1000, i18n.t('signalement.contact.message_max'))
    .trim(),
})

export const SignalementContact: FunctionComponent<StackScreenProps<ActionViewParamsType, 'SignalementContact'>> = ({ route }) => {
  const id = (route.params.id as number) || 0
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const signalementItem = useSelector((state: RootState) => signalementItemSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_SIGNALEMENT_ITEM))
  const { handleBlur, handleChange, handleSubmit, values, errors, isValid, isSubmitting, resetForm } = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async submitValues => {
      try {
        const response = await SignalementApi.submitSignalementComment(id, submitValues.message)
        if (response.success) {
          showSuccess(i18n.t('signalement.contact.success_message'), 'top', 3000)
          resetForm()
          navigation.goBack()
          dispatch(SignalementsActions.loadSignalementItem(id))
        }
      } catch (error) {
        showWarning(i18n.t('signalement.contact.error_message'), 'top', 3000)
      }
    },
  })

  useEffect(() => {
    dispatch(SignalementsActions.loadSignalementItem(id))
  }, [])

  if (!signalementItem || isLoading) {
    return (
      <Page left={<LeftButtonBack />} title={i18n.t('signalement.pageTitle')}>
        <Spinner marginTop="50%" color={theme.colors.blue} />
      </Page>
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={i18n.t('signalement.pageTitle')}>
      <View style={styles.content}>
        <Text style={styles.title}>{signalementItem.libelle}</Text>
        <Text style={styles.subtitle}>{i18n.t('signalement.contact.title')}</Text>
        <Text style={styles.info}>{i18n.t('signalement.contact.titleInfo')}</Text>

        <View style={styles.textAreaContainer}>
          <TextArea
            lineHeight={20}
            minHeight={250}
            autoCompleteType="message"
            testID="signalement-contact-message"
            onChangeText={handleChange('message')}
            onBlur={handleBlur('message')}
            maxLength={1002}
            value={values.message}
            style={[styles.textArea, { borderColor: errors.message ? theme.colors.red : theme.colors.silver }]}
          />

          {errors.message && <Text style={styles.error}>{errors.message}</Text>}

          <Button
            colorScheme="default"
            testID="signalement-contact-button"
            onPress={handleSubmit}
            style={[styles.contactBtn, isLoading || isSubmitting || !isValid || !values.message ? styles.buttonDisabled : styles.buttonEnabled]}
            disabled={isLoading || isSubmitting || !isValid || !values.message}
            leftIcon={<Icon as={Ionicons} name="send" color={theme.colors.white} size={5} mr={1} style={{ transform: [{ rotate: '-45deg' }] }} />}
          >
            <Text style={styles.contactLabel}>{i18n.t('signalement.contact.sendBtn')}</Text>
          </Button>
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: theme.padding.x2,
    flex: 1,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    color: theme.colors.silver,
  },
  subtitle: {
    width: '100%',
    color: theme.colors.black,
    fontWeight: 'bold',
    paddingBottom: theme.padding.x1,
  },
  info: {
    width: '100%',
    color: theme.colors.black,
    fontStyle: 'italic',
  },
  contactBtn: {
    width: '100%',
    marginTop: theme.padding.x2,
  },
  buttonDisabled: {
    backgroundColor: theme.colors.silver,
  },
  buttonEnabled: {
    backgroundColor: theme.colors.blue,
  },
  contactLabel: {
    textAlign: 'center',
    fontSize: 16,
    color: theme.colors.white,
  },
  label: {
    marginBottom: theme.padding.x1,
  },
  textAreaContainer: {
    marginVertical: theme.padding.x2,
  },
  textArea: {
    fontSize: theme.textSize.x2,
    borderColor: theme.colors.silver,
    borderWidth: 1,
    padding: theme.padding.x2,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
  error: {
    color: theme.colors.darkRed,
    marginBottom: theme.padding.x2,
  },
})
