import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import useCancelableTimeout from 'use-cancelable-timeout'
import { MarkerResult } from '../../../api/Cartography/types'
import { i18n } from '../../../lib/i18n'
import { Routes } from '../../../navigation/Routes'
import { themeSelector } from '../../../redux/theme/selector'
import { getItemAction } from '../../../services/itemAction'
import { theme } from '../../../theme'
import { FastImageBackground } from '../../FastImageBackground'

export const CARTOGRAPHY_CARD_HEIGHT = theme.deviceHeight / 2.8
const IMAGE_SIZE = 45
const BORDER_TOP_WIDTH = 4
const BORDER_RADIUS = 2

interface CartographyCardProps {
  item: MarkerResult
  isDisplayed?: boolean
  isClickable?: boolean
}

const CartographyCard: FunctionComponent<CartographyCardProps> = ({ item, isDisplayed = true, isClickable }) => {
  const [startAction, killAction] = useCancelableTimeout(() => {
    if (isClickable) {
      action()
    }
  }, 50)

  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  const action = getItemAction({
    type: 'view',
    viewName: Routes.Referentiel,
    params: { id: item.id, titre: item.libelle, image: item.image },
  })
  const goToMap = getItemAction({
    type: 'map',
    label: item.address || null,
    ...item.latlng,
  })

  useEffect(() => {
    if (!isClickable) killAction()
  }, [isClickable])

  if (!isDisplayed) {
    return null
  }

  return (
    <View style={[styles.container, { borderTopColor: colorText }]}>
      <FastImageBackground source={{ uri: item.image || undefined }} style={styles.image} resizeMode={FastImage.resizeMode.cover}>
        {item.icon && (
          <View style={[styles.imageContainer, { backgroundColor: colorText }]}>
            <FastImage style={styles.flex} source={{ uri: item.icon }} resizeMode={FastImage.resizeMode.contain} />
          </View>
        )}
        <View style={styles.footer}>
          <View style={styles.flex}>
            <Text numberOfLines={2} style={[styles.flex, { paddingBottom: theme.padding.x1 }]}>
              {item.libelle}
            </Text>
            <Text style={styles.note} numberOfLines={2}>
              {item.address}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              onPress={startAction}
              style={[styles.button, { borderColor: colorText }]}
              variant="outline"
              leftIcon={<Icon as={Ionicons} name="add" size={6} color={colorText} />}
            >
              <Text style={[{ color: colorText }]}>{i18n.t('cartographyCard.moreInfo')}</Text>
            </Button>

            <Button style={[styles.button, { backgroundColor: colorText }]} onPress={goToMap} leftIcon={<Icon as={Ionicons} name="navigate" size={6} style={styles.icon} />}>
              <Text style={{ color: themeSelect?.titleFontColor }}>{i18n.t('cartographyCard.itinerary')}</Text>
            </Button>
          </View>
        </View>
      </FastImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.white,
    height: CARTOGRAPHY_CARD_HEIGHT,
    flex: 1,
    borderTopWidth: BORDER_TOP_WIDTH,
    borderRadius: BORDER_RADIUS,
  },
  note: {
    opacity: 0.4,
  },
  flex: { flex: 1 },
  imageContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: BORDER_RADIUS,
    padding: theme.padding.x1,
    margin: theme.padding.x1,
  },
  image: {
    width: '100%',
    height: CARTOGRAPHY_CARD_HEIGHT,
    backgroundColor: theme.colors.silver,
    borderRadius: BORDER_RADIUS,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.white,
    padding: theme.padding.x2,
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.padding.x2,
  },
  button: {
    width: '48%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },

  icon: {
    transform: [{ rotate: '-45deg' }],
    color: theme.colors.white,
  },
})

export { CartographyCard }
