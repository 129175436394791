/* eslint-disable prettier/prettier */
import { Button, Icon, useTheme, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../redux/theme/selector'
import { goBack } from '../../services/navigation'
import { theme } from '../../theme'
import { Body, Header, Left, Right, Title } from '../NativeBaseLegacy'

export const WIDTH_LEFT_RIGHT = 80

interface Props {
  title?: string
  titleImage?: string | null
  left?: React.ReactNode
  right?: React.ReactNode
  headerBackgroundColor?: string
  children?: React.ReactNode
}

type LeftButtonBackType = {
  color?: string
  action?: () => void
}

export const Page: FunctionComponent<Props> = ({
  title,
  titleImage,
  children,
  left,
  right,
}) => {
  const themeNB = useTheme()
  const theme = useSelector(themeSelector)

  const renderTitle = () => {
    if (titleImage) {
      return (
        <FastImage
          style={styles.image}
          source={{ uri: titleImage }}
          resizeMode={FastImage.resizeMode.contain}
        />
      )
    }

    return <Title containerStyle={styles.title} style={[styles.titleText, { color: theme?.titleFontColor }]}>{title}</Title>
  }

  return (
    <View style={[styles.container, { backgroundColor: themeNB.colors.white }]}>
      <Header >
        <Left style={styles.left}>{left || undefined}</Left>
        <Body style={styles.body}>{renderTitle()}</Body>
        <Right style={styles.right}>{right || undefined}</Right>
      </Header>
        {children}
    </View>
  )
}

export const LeftButtonBack: FunctionComponent<LeftButtonBackType> = ({ color, action = null }) => {
  const theme = useSelector(themeSelector)
  return (
    <Button variant="ghost" onPress={action || goBack}>
      <Icon as={Ionicons} name="chevron-back" color={color || theme?.titleFontColor} size={7} />
    </Button>
  )
}

export function LeftRoundButtonBack() {
  const theme = useSelector(themeSelector)
  return (
  <Button borderRadius="full" onPress={goBack}>
    <Icon as={Ionicons} name="chevron-back" color={theme?.titleFontColor} size={7} />
  </Button>
)}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: theme.deviceHeight,
  },
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    justifyContent: 'center',
    flex: 1,
    zIndex: 1,
    alignItems: 'center',
  },
  right: {
    zIndex: 2,
    flex: 0,
    width: 100,
    justifyContent: 'flex-end',
  },
  title: {
  },
  titleText: {
    fontSize: theme.textSize.x2_50,
    fontWeight: 'bold',
  },
  image: {
    height: '100%',
    width: '100%',
    marginBottom: theme.padding.x1,
  },
})
