/* eslint-disable eslint-comments/no-unlimited-disable */
import isEmpty from 'lodash/isEmpty'
import { Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { ImageSourcePropType, StyleSheet, ViewStyle } from 'react-native'
import * as Animatable from 'react-native-animatable'
import HeaderImageScrollView, { TriggeringView } from 'react-native-image-header-scroll-view'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { theme } from '../../theme'
import { Body, Header, Left, Title } from '../NativeBaseLegacy'
import { HeaderCarrousel } from './components/HeaderCarrousel.component'
import { HeaderImage } from './components/HeaderImage.component'
import { HeaderMovie } from './components/HeaderMovie.component'

const MAX_HEIGHT = theme.deviceHeight / 2.7
const MIN_HEIGHT = 55
// const MIN_CHILDREN_HEIGHT = 1000

interface HeaderScrollProps {
  title: string
  foregroundImage?: string | null
  images: string[]
  left?: React.ReactNode
  leftHeader?: React.ReactNode
  right?: React.ReactNode
  imageDefault: ImageSourcePropType
  styleTitle?: ViewStyle
  children?: React.ReactNode
}

export const HeaderScrollView: FunctionComponent<HeaderScrollProps> = ({ children, foregroundImage, images, imageDefault, title, left, leftHeader, right, styleTitle }) => {
  const titleViewRef = React.createRef<Animatable.View>()
  const [displayBottom, setDisplayBottom] = useState(true)
  const safearea = useSafeAreaInsets()

  const onHideTitle = () => {
    setDisplayBottom(false)
    titleViewRef.current && titleViewRef.current.fadeInUp && titleViewRef.current.fadeInUp(200)
  }

  const onDisplayTitle = () => {
    setDisplayBottom(true)
    titleViewRef.current && titleViewRef.current.fadeOut && titleViewRef.current.fadeOut(100)
  }

  const renderImage = () => {
    if (foregroundImage) {
      const firstImage = images[0]
      const fbImage = firstImage ? foregroundImage : undefined
      const bgImage = firstImage || foregroundImage
      return <HeaderMovie foregroundImage={fbImage} backgroundImage={bgImage} left={left} right={right} displayBottom={displayBottom} maxHeight={MAX_HEIGHT} />
    }
    if (!isEmpty(images)) {
      if (images.length > 1) {
        return <HeaderCarrousel images={images} maxHeight={MAX_HEIGHT} left={left} displayBottom={displayBottom} />
      }
      return <HeaderImage fastImage={images[0]} left={left} displayBottom={displayBottom} maxHeight={MAX_HEIGHT} />
    }

    return <HeaderImage image={imageDefault} left={left} displayBottom={displayBottom} maxHeight={MAX_HEIGHT} />
  }

  return (
    <View style={styles.contentFlex}>
      <HeaderImageScrollView
        maxHeight={MAX_HEIGHT}
        minHeight={MIN_HEIGHT + safearea.top}
        overlayColor="transparent"
        renderForeground={renderImage}
        renderFixedForeground={() => (
          <Header>
            <Left>{leftHeader || undefined}</Left>
            <Body>
              <Animatable.View
                style={styles.titleView}
                /* eslint-disable-next-line */
                // @ts-ignore
                ref={titleViewRef}
              >
                <Title style={styles.headerTitle}>{title}</Title>
              </Animatable.View>
            </Body>
          </Header>
        )}
      >
        <SafeAreaView>
          <TriggeringView style={styles.section} onBeginHidden={onHideTitle} onDisplay={onDisplayTitle}>
            <Text style={[styles.title, styleTitle]}>{title}</Text>
          </TriggeringView>
          <View style={styles.viewChidrens}>{children}</View>
        </SafeAreaView>
      </HeaderImageScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  contentFlex: {
    backgroundColor: theme.colors.white,
    height: theme.deviceHeight,
  },
  headerTitle: {
    fontSize: theme.header.titleSize,
    color: theme.colors.white,
  },
  title: {
    fontSize: theme.textSize.x3,
    lineHeight: theme.textSize.x3,
    textAlign: 'center',
    width: '100%',
  },
  section: {
    paddingHorizontal: theme.padding.x2,
    textAlign: 'center',
    backgroundColor: theme.colors.transparent,
  },
  titleView: {
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
  },
  viewChidrens: {},
})
