import { BaseApi } from '../BaseApi'
import { FormResponseResult, FormSubmit } from '../Form/types'
import { urlApi } from '../url'
import { CategoriesResult, SignalementConfigResult, SignalementMessageResult, SignalementsResult } from './types'

export class SignalementApi {
  public static async load(page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url('v1/signalements')
      .query({ page, size })
      .get()
      .json(json => json) as Promise<SignalementsResult>
  }

  public static async loadItem(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/signalements/${id}`)
      .get()
      .json(json => json) as Promise<SignalementsResult>
  }

  public static async loadSignalement(model?: string) {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.default)
      .query(model ? { model } : {})
      .get()
      .json(json => json) as Promise<SignalementConfigResult>
  }

  public static async submitSignalement(form: FormSubmit, model?: string) {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.submit)
      .query(model ? { model } : {})
      .post(form)
      .json(json => json) as Promise<FormResponseResult>
  }

  public static async loadCategories() {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.categories)
      .get()
      .json(json => json) as Promise<CategoriesResult>
  }

  public static async submitSignalementComment(id: number, message: string) {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.addComment(id))
      .post({ comment: message })
      .json(json => json) as Promise<SignalementMessageResult>
  }
}
