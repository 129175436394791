export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '41cdd614-7d25-4103-9c6a-faf58975f0f8',
  ENV: 'citopiaIc',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
  },
  baseSurveyUrl: 'https://sondage.vooter.co/survey',
}
