export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '95a068c3-f559-434c-b27c-cde59987371d',
  ENV: 'lorient',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
  },
  matomoUrlBase: 'https://matomo.lorient.bzh/',
  matomoSiteId: 6,
  baseSurveyUrl: 'https://sondage.vooter.co/survey',
}
