export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '1b63536a-8873-469e-8a8e-b7d09eb9412e',
  ENV: 'citopiaJvs',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
  },
  baseSurveyUrl: 'https://sondage.vooter.co/survey',
}
