export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: 'd916131e-71ba-4288-bf8f-0bf835a8ad50',
  ENV: 'rueilMalmaisonStaging',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  baseSurveyUrl: 'https://sondage.vooter.co/survey',
}
